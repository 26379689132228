<template>
  <div>
    <v-card elevation="0" class="px-5">
      <v-row>
        <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filterData.from">
            </v-text-field>
        </v-col>
        <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filterData.to">
            </v-text-field>
        </v-col>
        <v-col md="3">
          <AutoCompleteField endPoint="/student/auto-complete"type="parent_order" :label="$t('student name')" v-model="filterData.registration_record_id" />
        </v-col>
        <v-col md="3">
          <AutoCompleteField end-point="/user/auto-complete" :label="$t('father name')" v-model="filterData.parent_id" />
        </v-col>
        <v-col md="3">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('value')" v-model="filterData.amount" type="number"></v-text-field>
        </v-col>
        <v-col md="2">
          <v-autocomplete :dark="$store.state.isDarkMode" :label="$t('state')" :items="stateItems" color="#757575" item-text="name" item-value="value"
            v-model="filterData.status" no-filter>
          </v-autocomplete>
        </v-col>
        <v-col md="2">
          <v-text-field :dark="$store.state.isDarkMode" :label="$t('explain')" v-model="filterData.desc"></v-text-field>
        </v-col>
        <v-col md="2" class="d-flex justify-space-between align-center">
          <v-icon :dark="$store.state.isDarkMode" @click="filterTable" size="30">mdi-magnify</v-icon>
          <v-icon :dark="$store.state.isDarkMode" size="30" @click="resetFilter">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Notifications from "../../../../helpers/toast-notifications";
import ExportExcel from "../../components/core/ExportExcel.vue";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
export default {
  components: {
      ExportExcel,
      AutoCompleteField,
    },
  data() {
    return {
      orderTypes: [
        {
          name: this.$t('deposit'),
          value: "deposit",
        },
        {
          name: this.$t('pay'),
          value: "pay",
        },
      ],
      filterData: {
        from: "",
        to: "",
        registration_record_id: null,
        amount: null,
        parent_id: null,
        desc: "",
        status: null,
        page: 1,
      },
    };
  },

  methods: {
    checkValidation() {
      if (this.filterData.from && this.filterData.to) {
        if (new Date(this.filterData.from) > new Date(this.filterData.to)) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    resetFilter() {
      this.filterData.from = "";
      this.filterData.to = "";
      this.filterData.registration_record_id = null;
      this.filterData.amount = null;
      this.filterData.parent_id = null;
      this.filterData.desc = "";
      this.filterData.status = null;
      this.filterData.page = 1;
      this.$emit("resetFilter");
    },
    filterTable() {
      if (this.checkValidation()) {
        this.$emit("filterTable", this.filterData);
      } else {
        Notifications(
          this.$t("date error"),
          { timeout: 4000, rtl: true },
          "info"
        );
      }
      console.log("data", this.filterData);
    },
  },
  computed: {
    stateItems() {
      return [
        {
          name: this.$t("accepted"),
          value: "1",
        },
        {
          name: this.$t("rejected"),
          value: "'0'",
        },
      ];
    },
  },
};
</script>

<style></style>