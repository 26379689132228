<template>
  <div>
    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="orderType == 'deposit'" v-on="on" v-bind="attrs" rounded depressed color="green"
          class="white--text">
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add a receipt") }}
        </v-btn>
        <v-btn v-if="orderType == 'pay'" v-on="on" v-bind="attrs" rounded depressed color="green"
          class="white--text ml-2">
          <v-icon>mdi-plus</v-icon>
          {{ $t("Add a payment order") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center card-title__heading">
          <h2 v-if="orderType == 'pay'">{{ $t("Add a payment order") }}</h2>
          <h2 v-if="orderType == 'deposit'">{{ $t("Add a receipt") }}</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="8">
              <AutoCompleteField :label="$t('student name')" type="parent_order" v-model="financialOrderData.registration_record_id"
                        endPoint="/student/auto-complete" returnObject @input="inputParent"/>
            </v-col>
            <v-col md="4">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('date')" type="date" v-model="financialOrderData.date">
                </v-text-field>
            </v-col>
              <v-col md="4">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('father name')" readonly v-model="fatherName">
                </v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" type="number" color="#757575" :label="$t('value')"
                v-model="financialOrderData.amount"></v-text-field>
            </v-col>
            <v-col md="4">
              <AutoCompleteField :label="$t('Box Account')" cash="1" v-model="financialOrderData.box_account_id"
                        endPoint="/accounting/account/auto-complete" returnObject/>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" :label="$t('user name')" readonly v-model="username"></v-text-field>
            </v-col>
            <v-col md="8">
              <v-text-field :dark="$store.state.isDarkMode" :label="$t('explain')" v-model="financialOrderData.desc" color="#757575"></v-text-field>
            </v-col>
            <v-col md="4" class="d-flex justify-end align-center">
              <v-btn :disabled="disableBtn" class="white--text" color="green" :loading="submitBtnLoading"
                @click="submitOrder" v-if="orderType == 'pay'">{{ $t("create a payment order") }}
              </v-btn>
              <v-btn :disabled="disableBtn" class="white--text" color="green" @click="submitOrder"
                :loading="submitBtnLoading" v-if="orderType == 'deposit'">
                {{ $t("create a Receipt Order") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showIdDialog" width="600" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center align-center">
          <h1>رقم الأمر :{{ idNumber }}</h1>
        </v-card-title>
        <v-card-actions class="d-flex justify-center align-center">
          <v-btn color="green" class="white--text" @click="fetchData">موافق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import AutoCompleteField from "../../components/core/AutoCompleteField";
export default {
  components: {
    AutoCompleteField,
  },
  data() {
    return {
      fatherName: null,
      costCenterItems: [],
      costCenterLoading: false,
      accountItems: [],
      accountLoading: false,
      dateDialog: false,
      boxItems: [],
      boxesLoading: false,
      dialog: false,
      financialOrderData: {
        registration_record_id: null,
        box_account_id: null,
        date: new Date().toISOString().slice(0, 10),
        amount: null,
        reciever_id: null,
        desc: "",
      },
      submitBtnLoading: false,
      showIdDialog: false,
      idNumber: null,
    };
  },
  computed: {
    username() {
      return this.$store.getters.getUserName;
    },
    disableBtn() {
      if (
        !this.financialOrderData.box_account_id ||
        !this.financialOrderData.registration_record_id ||
        !this.financialOrderData.date ||
        !this.financialOrderData.amount ||
        !this.financialOrderData.reciever_id ||
        !this.financialOrderData.desc
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    dialog: function () {
      if(!this.dialog){
        this.fatherName = null;
        this.financialOrderData = {
        registration_record_id: null,
        box_account_id: null,
        date: new Date().toISOString().slice(0, 10),
        amount: null,
        reciever_id: null,
        desc: "",
      };
      }
    }
  },
  props: ["orderType"],
  methods: {
    inputParent(){
      this.fatherName = this.financialOrderData.registration_record_id.parent.name;
      this.financialOrderData.reciever_id = this.financialOrderData.registration_record_id.parent.id
    },
    fetchData() {
      this.showIdDialog = false;
      this.$emit("reFetchDataAgain");
    },
    async submitOrder() {
      try {
        let requestBody = {
          box_account_id: this.financialOrderData.box_account_id?.id,
          date: this.financialOrderData.date,
          amount: this.financialOrderData.amount,
          reciever_id: this.financialOrderData.reciever_id,
          desc: this.financialOrderData.desc,
          registration_record_id: this.financialOrderData.registration_record_id?.id,
        };
        if (!this.financialOrderData.desc) {
          delete requestBody.desc;
        }
        this.submitBtnLoading = true;
        const response = await axios.post(
          "/accounting/app-financial-order",
          requestBody
        );
        this.dialog = false;
        this.$Notifications(
          this.$t('add success'),
            { rtl: true, timeout: 1000 },
            "success"
        );
        this.$emit("reFetchDataAgain");
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },

   
  },
};
</script>

<style></style>
