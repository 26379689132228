<template>
    <v-container>
      <v-card elevation="0" class="pt-5">
        <v-row>
          <v-col md="6" class="py-5">
            <app-create-financial-order v-if="returnAbility('app_financial_order:store')" @reFetchDataAgain="fetchData(1)"
              orderType="deposit"></app-create-financial-order>
          </v-col>
        </v-row>
        <app-financial-order-filter v-if="returnAbility('financial-order:index')"
          @filterTable="fetchData(1, (filterObject = $event))"
          @resetFilter="resetFilterTable"></app-financial-order-filter>

        <app-base-table :tableOptions="tableOptions" v-if="returnAbility('app_financial_order:index')"
          @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"
          :serverRoute="'/accounting/app-financial-order'" :enableDelete="displayDeleteIcon" :flaggedItems="flaggedItemsData">
          <template slot="editFeature" slot-scope="{ row }">
            <app-edit-financial-order v-if="returnAbility('app_financial_order:update')"
              :recordData="row"></app-edit-financial-order>
              <v-btn v-if="returnAbility('app_financial_order:accept')" @click="acceptOrder(row.id)" color="green" :loading="loadingAccept" icon>
                <v-icon v-if="row.status != 'none'" class="ml-1">mdi-check-all</v-icon>
                <v-icon v-else class="ml-1">mdi-check</v-icon>
              </v-btn>
          </template>
        </app-base-table>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import axios from "axios";
  import createFinancialOrder from "./createFinancialOrder.vue";
  import editFinancialOrder from "./editFinancialOrder.vue";
  import { mapActions, mapGetters } from "vuex";
  import financialOrderFilter from "./financialOrderFilter.vue";
  export default {
    components: {
      appEditFinancialOrder: editFinancialOrder,
      appFinancialOrderFilter: financialOrderFilter,
      appCreateFinancialOrder: createFinancialOrder,
    },
    data() {
      return {
        loadingAccept: false,
        boxes: [],
        boxesLoading: false,
        flaggedItemsData: ["notes", "action"],
        filterObject: {
          from: "",
          to: "",
          registration_record_id: null,
          parent_id: null,
          amount: null,
          desc: "",
          status: null,
          page: 1,
        },
      };
    },
    computed: {
      ...mapGetters({
        tableOptions: "accountantFinancialOrder/getTableOptionsParents"
      }),
    },
    methods: {
      ...mapActions({
        fetchCashierFinancialOrder:
          "accountantFinancialOrder/fetchFinancialOrderParents",
      }),
     async acceptOrder(id){
       try {
        this.loadingAccept = true;
        let requestBody = {
          id: id,
        };
        const res = await axios.post("/accounting/app-financial-order/accept",
          requestBody);
          if(res.status == 200){
            this.$Notifications(
          this.$t('Accept Success'),
            { rtl: true, timeout: 1000 },
            "success"
        );
          }
          this.loadingAccept = false;
       } catch (error) {
        this.loadingAccept = false;
       }
      },
      resetFilterTable() {
        this.filterObject.from = "";
        this.filterObject.to = "";
        this.filterObject.amount = null;
        this.filterObject.registration_record_id = null;
        this.filterObject.parent_id = null;
        this.filterObject.desc = "";
        this.filterObject.status = null,
        this.filterObject.page = 1;
      },
      fetchData(paginationValue) {
        if (!this.returnAbility("financial-order:index")) return;
        this.fetchCashierFinancialOrder({
          paginationValue,
          tableFilter: this.filterObject,
        });
      },
    },
    async created() {
      try {
        if (this.returnAbility("app_financial_order:delete")) {
          this.displayDeleteIcon = true;
        }
        this.fetchData(1);
        this.boxesLoading = true;
        if (this.returnAbility("cashier-box:show")) {
          const boxes = await axios.get("/accounting/cashier-box/my-box", {
            params: {
              size: 10000,
            },
          });
          this.boxes.unshift({
            name: this.$t('all'),
            id: null,
          });
          boxes.data.data.forEach((element) => {
            this.boxes.push(element.box);
          });
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        this.boxesLoading = false;
      }
    },
  };
  </script>