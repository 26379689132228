<template>
  <v-dialog v-model="editDialog" width="700" >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info" @click="fetchData()">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="8">
              <AutoCompleteField :label="$t('student name')" type="parent_order" v-model="financialOrderData.registration_record_id"
                        endPoint="/student/auto-complete" returnObject @input="inputParent"/>
            </v-col>
            <v-col md="4">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('date')" type="date" v-model="financialOrderData.date">
                </v-text-field>
            </v-col>
              <v-col md="4">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('father name')" readonly v-model="fatherName">
                </v-text-field>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" type="number" color="#757575" :label="$t('value')"
                v-model="financialOrderData.amount"></v-text-field>
            </v-col>
            <v-col md="4">
              <AutoCompleteField :label="$t('Box Account')" cash="1" v-model="financialOrderData.box_account_id"
                        endPoint="/accounting/account/auto-complete" returnObject/>
            </v-col>
            <v-col md="4">
              <v-text-field :dark="$store.state.isDarkMode" :label="$t('user name')" readonly v-model="username"></v-text-field>
            </v-col>
            <v-col md="8">
              <v-text-field :dark="$store.state.isDarkMode" :label="$t('explain')" v-model="financialOrderData.desc" color="#757575"></v-text-field>
            </v-col>
          <v-col md="4" class="d-flex justify-end align-center">
            <v-btn class="white--text" color="green" :loading="editBtnLoading" @click="editRecord"
              > {{ $t('edit') }} </v-btn>
            <!-- :disabled="disableBtn" -->
            <!-- <v-btn class="white--text" color="green" @click="editRecord" :loading="editBtnLoading"
              v-if="financialOrderData.action == 'deposit'">تعديل أمر قبض</v-btn> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center"> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import Notifications from "../../../../helpers/toast-notifications";
import AutoCompleteField from "../../components/core/AutoCompleteField";

export default {
  components: {
    AutoCompleteField,
  },
  data() {
    return {
      fatherName: null,
      editBtnLoading: false,
      editDialog: false,
      accountItems: [],
      accountLoading: false,
      dateDialog: false,
      boxItems: [],
      boxesLoading: false,
      dialog: false,
      financialOrderData: {
        box_account_id: null,
        registration_record_id: null,
        date: new Date().toISOString().slice(0, 10),
        amount: null,
        reciever_id: null,
        desc: "",
      },
      showIdDialog: false,
      idNumber: null,
      username: "",
    };
  },
  // computed: {
  //   disableBtn() {
  //     if (
  //       1
  //       // !this.financialOrderData.box ||
  //       // !this.financialOrderData.date ||
  //       // !this.financialOrderData.amount ||
  //       // !this.financialOrderData.account ||
  //       // !this.financialOrderData.desc ||
  //       // !this.financialOrderData.cost_center
  //     ) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   },
  // },
  props: {
    recordData: Object,
  },
  watch: {
    dialog: function () {
      if(!this.dialog){
        this.fatherName = null;
        this.financialOrderData = {
        registration_record_id: null,
        box_account_id: null,
        date: new Date().toISOString().slice(0, 10),
        amount: null,
        reciever_id: null,
        desc: "",
      };
      }
    }
  },
  methods: {
    inputParent(){
      this.fatherName = this.financialOrderData.registration_record_id.parent.name;
      this.financialOrderData.reciever_id = this.financialOrderData.registration_record_id.parent.id
    },
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/accounting/app-financial-order/${this.recordData.id}`, {
          // box_id: this.financialOrderData.box.id,
          date: this.financialOrderData.date,
          amount: this.financialOrderData.amount,
          reciever_id: this.financialOrderData.reciever_id,
          desc: this.financialOrderData.desc,
          registration_record_id: this.financialOrderData.registration_record_id?.id,
          box_account_id: this.financialOrderData.box_account_id?.id,
        });
        Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
    
    

  async fetchData(){
    try {
      let res = await axios.get(`/accounting/app-financial-order/${this.recordData.id}`);
       this.fatherName = res.data.data.reciever.name;
       let ddd = res.data.data.date.split(" ");
       this.financialOrderData.date = ddd[0];
       this.financialOrderData.amount = res.data.data.amount;
       this.financialOrderData.desc = res.data.data.desc;
       this.financialOrderData.reciever_id = res.data.data.reciever.id;
       this.financialOrderData.registration_record_id = res.data.data.registration_record;
       this.financialOrderData.box_account_id = res.data.data.box_account;
       this.username = res.data.data.generator.username;

    } catch (error) {
      console.log(error);
    }
      

    },
  },
 async created() {

  },
};
</script>

<style></style>